@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 20px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background-color: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  height: 20px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
  text-anchor: end;
  fill: #ffffff !important;
  font-size: 15px !important;
  transform: rotate(-45deg) translateY(10px);
}

.MuiChartsAxis-directionX .MuiChartsAxis-label {
  transform: translateY(30px);
  fill: #ffffff !important;
}

.MuiChartsAxis-directionY .MuiChartsAxis-tickLabel {
  fill: #ffffff !important;
}

.MuiChartsAxis-directionY .MuiChartsAxis-label {
  transform: translateX(-20px);
  fill: #ffffff !important;
}

@media (max-width: 768px) {
  .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    font-size: 8px;
  }

  .MuiChartsAxis-directionX .MuiChartsAxis-label {
    transform: translateY(20px);
  }

  .MuiChartsAxis-directionY .MuiChartsAxis-label {
    transform: translateX(0px);
  }
}

@media (max-width: 480px) {
  .MuiChartsAxis-directionX .MuiChartsAxis-tickLabel {
    font-size: 2px !important;
  }

  .MuiChartsAxis-directionX .MuiChartsAxis-label {
    transform: translateY(30px);
  }

  .MuiChartsAxis-directionY .MuiChartsAxis-label {
    transform: translateX(-25px);
  }
}

body {
  background-color: #111827; /* Equivalent to bg-gray-950 */
}
